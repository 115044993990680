export enum AdTemplateActionEvents {
  GET_ALL_ORGANISATIONS = 'GET_ALL_ORGANISATIONS',
  ERROR_GETTING_ALL_ORGANISATIONS = 'ERROR_GETTING_ALL_ORGANISATIONS',

  GET_ALL_LEAD_FORMS = "GET_ALL_LEAD_FORMS",
  ERROR_GETTING_ALL_LEAD_FORMS = 'ERROR_GETTING_ALL_LEAD_FORMS',

  GET_ALL_AD_TEMPLATES = 'GET_ALL_AD_TEMPLATES',
  ERROR_GETTING_ALL_AD_TEMPLATES = 'ERROR_GETTING_ALL_AD_TEMPLATES',

  ADD_AD_TEMPLATE = 'ADD_AD_TEMPLATE',
  ERROR_ADDING_AD_TEMPLATE = 'ERROR_ADDING_AD_TEMPLATE',

  UPDATE_AD_TEMPLATE = 'UPDATE_AD_TEMPLATE',
  ERROR_UPDATING_AD_TEMPLATE = 'ERROR_UPDATING_AD_TEMPLATE',

  DELETE_AD_TEMPLATE = 'DELETE_AD_TEMPLATE',
  ERROR_DELETING_AD_TEMPLATE = 'ERROR_DELETING_AD_TEMPLATE',

  SET_AD_TEMPLATES_LOADER = 'SET_AD_TEMPLATES_LOADER',
}
