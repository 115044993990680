import { Reducer } from 'redux';

import { behavioursActionEvents as EVENT } from '../actions/behaviours.action.event.enums';
import { behavioursInitState } from './types';

const initState: behavioursInitState = {
  behaviours: [],
  error: null
};

const behavioursReducer: Reducer<behavioursInitState> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case EVENT.GET_BEHAVIOURS: {
      return { ...state, behaviours: action.payload };
    }

    case EVENT.GET_BEHAVIOURS_ERROR: {
      return { ...state, error: action.error };
    }

    default: {
      break;
    }
  }
  return state;
};

export default behavioursReducer;
