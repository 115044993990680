import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';

import "./App.scss";
import { ToastContainer } from "react-toastify";

import { get } from "lodash";
import { connect } from "react-redux";

import {
  getEnums,
} from "./state/defaults/actions/http.default.action";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));

export class App extends Component {
  componentDidMount() {
    if (this.props.isAuthenticated) this.props.getEnums();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) this.props.getEnums();
  }

  render() {
    return (
      <>
        <ToastContainer />
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />

              <Route
                path="/"
                name="Home"
                render={(props) => <DefaultLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>
    );
  }
}

const mapStateToProps = (storeState) => ({ isAuthenticated: get(storeState, "auth.isAuthenticated") || false });

const mapDispatchToProps = (dispatch) => ({ getEnums: () => dispatch(getEnums()) });

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default ConnectedApp;
