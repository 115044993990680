export default function reducer(state={
    error:null,
    totalActive:0,
    totalPartners:0,
    
  },action){
    switch (action.type){
        
      case "GET_TOTAL_ACTIVE":{
         return {...state, totalActive:action.total}
      }

      case "GET_TOTAL_PARTNERS":{
        return {...state, totalPartners:action.total}
      }

      default:{
        break;
      }

      
    }
    return state;
  }
  