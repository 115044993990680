import { vasActionEvents as EVENT } from '../actions/vas.action.events.enums';

export default function vasModalReducer(
  state = {
    isOpenned: false,
    isViewOpen: false,
    isEditOpen: false,
    rowData: {},
    error: null
  },
  action: any
) {
  switch (action.type) {
    case EVENT.TOGGLE_VAS_ADD_MODAL: {
      return { ...state, isOpenned: action.status };
    }

    case EVENT.TOGGLE_VAS_VIEW_MODAL: {
      return { ...state, isViewOpen: action.status };
    }

    case EVENT.TOGGLE_EDIT_VAS_MODAL: {
      return { ...state, isEditOpen: action.status };
    }

    case EVENT.CLICKED_ROW_DATA_VAS: {
      return { ...state, rowData: action.rows };
    }

    case EVENT.CLOSE_VAS_MODAL: {
      return { ...state, isOpenned: action.status };
    }

    default: {
      break;
    }
  }
  return state;
}
