import _ from 'lodash';

import alert from '../views/Components/Toast/index';

export const onSuccess = (res: any) => (dispatch: Function) => {
  displayAlert("success", res.message);
  dispatch({ type: res.type, payload: res.payload });
};

export const onError = (res: any) => (dispatch: Function) => {
  const message = _.has(res, "error.response.data.message")
    ? res.error.response.data.message
    : res.error.message;

  displayAlert("error", message);
  dispatch({ type: res.type, error: res.error });
};

const displayAlert = (type: string, message: string) => {
  if (!_.isEmpty(message)) alert(type, message, "BOTTOM_RIGHT");
};
