export enum vasActionEvents {
  GET_UNFULFILLED_ACQUIRED_VAS = "GET_UNFULFILLED_ACQUIRED_VAS",
  GET_FULFILLED_ACQUIRED_VAS = "GET_FULFILLED_ACQUIRED_VAS",
  ERROR_GETTING_VAS = "ERROR_GETTING_VAS",
  //MODAL
  TOGGLE_VAS_ADD_MODAL = "TOGGLE_VAS_ADD_MODAL",
  TOGGLE_VAS_VIEW_MODAL = "TOGGLE_VAS_VIEW_MODAL",
  TOGGLE_EDIT_VAS_MODAL = "TOGGLE_EDIT_VAS_MODAL",
  CLICKED_ROW_DATA_VAS = "CLICKED_ROW_DATA_VAS",
  CLOSE_VAS_MODAL = "CLOSE_VAS_MODAL",
  ADDED_FIELDS_VAS = "ADDED_FIELDS_VAS"
}
