import { partnersActionEvents as EVENT } from '../actions/partners.action.event.enums';

export default function modalReducer(
  state = {
    isOpenned: false,
    isViewOpen: false,
    isEditOpen: false,
    rowData: {},
    error: null
  },
  action: any
) {
  switch (action.type) {
    case EVENT.TOGGLE_PARTNERS_ADD_MODAL: {
      return { ...state, isOpenned: action.status };
    }

    case EVENT.TOGGLE_PARTNERS_VIEW_MODAL: {
      return { ...state, isViewOpen: action.status };
    }

    case EVENT.TOGGLE_PARTNERS_EDIT_MODAL: {
      return { ...state, isEditOpen: action.status };
    }

    case EVENT.CLICKED_PARTNERS_ROW_DATA: {
      return { ...state, rowData: action.rows };
    }

    case EVENT.CLOSE_PARTNERS_MODAL: {
      return { ...state, isOpenned: action.status };
    }

    default: {
      break;
    }
  }
  return state;
}
