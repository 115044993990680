import { toast } from 'react-toastify';

export default function alerts(
  type: string,
  message: string,
  index = "TOP_RIGHT"
) {
  toast.dismiss();

  switch (type) {
    case "success": {
      toast.success(message, {
        position: (toast.POSITION as any)[index],
        type: toast.TYPE.SUCCESS
      });

      break;
    }

    case "error": {
      toast.error(message, {
        position: (toast.POSITION as any)[index],
        type: toast.TYPE.ERROR
      });

      break;
    }

    case "warning": {
      toast.warn(message, {
        position: (toast.POSITION as any)[index],
        type: toast.TYPE.WARNING
      });

      break;
    }

    case "info": {
      toast.info(message, {
        position: (toast.POSITION as any)[index],
        type: toast.TYPE.INFO
      });

      break;
    }

    default: {
      return false;
    }
  }
}
