import { checkTagById, initializeTags } from '../actions/http.user.lookup.action';
import { userLookUpActionEvents as EVENT } from '../actions/user.lookup.action.event.enums';
import { UserLookUpState } from './types';

const initState: UserLookUpState = {
  userInfo: [],
  transactions: [],
  leaseAgreements: [],
  error: null,
  tags: [],
};

export default function userLookUpreducer(
  state = initState,
  action: any
): UserLookUpState {
  switch (action.type) {
    case EVENT.GET_USER_BY_EMAIL: {
      return { ...state, userInfo: action.payload };
    }

    case EVENT.GET_LEASE_REQUEST: {
      return { ...state, leaseAgreements: action.payload };
    }

    case EVENT.GET_LEASE_SUCCESS: {
      return { ...state, leaseAgreements: action.payload };
    }

    case EVENT.GET_LEASE_FAILURE: {
      return { ...state, error: action.error };
    }

    case EVENT.GET_TAGS_REQUEST: {
      return { ...state, tags: [], error: null };
    }
    case EVENT.GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: initializeTags(action.payload),
        error: null,
      };
    }

    case EVENT.GET_TAGS_FAILURE: {
      return { ...state, error: action.error };
    }

    case EVENT.CHECK_SELECTED_TAG: {
      return {
        ...state,
        tags: checkTagById(state.tags, action.id, action.makeTrue),
      };
    }

    case EVENT.UPDATE_USER_STATUS_REQUEST:
    case EVENT.UPDATE_ACCOUNT_TYPE_REQUEST:
    case EVENT.CHANGE_LOGIN_TYPE_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }

    case EVENT.UPDATE_ACCOUNT_AS_VERIFIED:
    case EVENT.UPDATE_USER_STATUS_SUCCESS:
    case EVENT.CHANGE_LOGIN_TYPE_SUCCESS: {
      return {
        ...state,
        error: null,
        userInfo: action.payload,
      };
    }

    case EVENT.UPDATE_ACCOUNT_TYPE_SUCCESS:{
      return {
        ...state,
        error: null,
        userInfo: { ...state.userInfo, ...{ account: action.payload }},
      };
    }

    case EVENT.UPDATE_ACCOUNT_AS_VERIFIED_FAILURE:
    case EVENT.GET_USER_TRANSACTIONS_BY_EMAIL_ERROR:
    case EVENT.GET_USER_BY_EMAIL_ERROR:
    case EVENT.UPDATE_USER_STATUS_FAILURE:
    case EVENT.UPDATE_ACCOUNT_TYPE_FAILURE:
    case EVENT.CHANGE_LOGIN_TYPE_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case EVENT.GET_USER_TRANSACTIONS_BY_EMAIL: {
      return {
        ...state,
        error: null,
        transactions: action.payload,
      };
    }

    default: {
      break;
    }
  }
  return state;
}
