import { userLookUpActionEvents as EVENT } from '../actions/user.lookup.action.event.enums';

export default function modalReducer(
  state = {
    isOpen: false
  },
  actions: any
) {
  switch (actions.type) {
    case EVENT.OPEN_POINTS_MODAL: {
      return { ...state, isOpen: actions.status };
    }
    default: {
      break;
    }
  }
  return state;
}
