import { Reducer } from 'redux';

import { partnersActionEvents as EVENT } from '../actions/partners.action.event.enums';
import { partnerInitState } from './types';

const initState: partnerInitState = {
  partners: [],
  loader: false,
  error: null
};

const partnerReducer: Reducer<partnerInitState> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case EVENT.GET_ALL_PARTNERS: {
      return { ...state, partners: action.payload };
    }

    case EVENT.ADD_PARTNERS: {
      return { ...state, partners: [...action.payload, ...state.partners] };
    }

    case EVENT.ERROR_GETTING_PARTNERS: {
      return { ...state, error: action.error };
    }

    case EVENT.ERROR_EDDITING_PARTNERS: {
      return { ...state, error: action.error };
    }
    case EVENT.SET_PARTNER_LOADER: {
      return { ...state, loader: action.bool };
    }

    default: {
      break;
    }
  }
  return state;
};

export default partnerReducer;
