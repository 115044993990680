import { campaignActionEvents as EVENT } from '../../campaigns/actions/campaign.action.event.enums';

export default function campaignModalReducer(
  state = {
    isOpenned: false,
    isViewOpen: false,
    isEditOpen: false,
    rowData: {},
    error: null,
    menuOpened: false,
    arrFields: null,
    fields: {
      sheet: 0,
      column: ""
    }
  },
  action: any
) {
  switch (action.type) {
    case EVENT.TOGGLE_CAMPAIGN_ADD_MODAL: {
      return { ...state, isOpenned: action.status };
    }

    case EVENT.TOGGLE_CAMPAIGN_VIEW_MODAL: {
      return { ...state, isViewOpen: action.status };
    }

    case EVENT.TOGGLE_EDIT_CAMPAIGN_MODAL: {
      return { ...state, isEditOpen: action.status };
    }

    case EVENT.CLICKED_CAMPAIGN_ROW_DATA: {
      return { ...state, rowData: action.rows };
    }

    case EVENT.CLOSE_CAMPAIGN_MODAL: {
      return { ...state, isOpenned: action.status };
    }

    //Excel filed selection menu
    case EVENT.TOGGLE_MENU: {
      return { ...state, menuOpened: action.status };
    }

    //fields
    case EVENT.ADDED_FIELDS: {
      return { ...state, fields: action.fields };
    }

    //set array fields
    case EVENT.SET_FIELDS: {
      return { ...state, arrFields: action.arrFields };
    }

    default: {
      break;
    }
  }
  return state;
}
