import { vasActionEvents as EVENT } from '../actions/vas.action.events.enums';
import { acquiredVasState } from './types';

const initState: acquiredVasState = {
  unfulfilledData: [],
  resolvedData: [],
  error: null
};

export default function reducer(
  state = initState,
  action: any
): acquiredVasState {
  switch (action.type) {
    case EVENT.GET_UNFULFILLED_ACQUIRED_VAS: {
      return { ...state, unfulfilledData: action.payload };
    }

    case EVENT.GET_FULFILLED_ACQUIRED_VAS: {
      return { ...state, resolvedData: action.payload };
    }

    default: {
      break;
    }
  }
  return state;
}
