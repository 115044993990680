import { Reducer } from 'redux';

import { flowProductEnums as EVENT } from '../actions/flow-product.actions.enum';
import { FlowProductInitState } from './types';

const initState: FlowProductInitState = {
  flowProducts: [],
  loader: false,
  error: null
};

const flowProductReducer: Reducer<FlowProductInitState> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case EVENT.GET_ALL_PRODUCTS: {
      return { ...state, flowProducts: action.payload };
    }

    case EVENT.FLOW_PRODUCT_ERROR: {
      return { ...state, error: action.error };
    }

    default: {
      break;
    }
  }
  return state;
};

export default flowProductReducer;
