import { CreditManagementActionEvents } from '../actions/credit-management.action.event.enums';
import { Credit, CreditBalance, CreditManagementState } from './types';

const initState: CreditManagementState = {
  organisation: null,
  agent: null,
  credits: [],
  loader: false,
  error: null,
  creditBalance: {
    totalCredits: 0,
    totalAccountCredits: 0
  }
};
// update creditbalance function
function updateCreditBalance(creditBalance: CreditBalance, category: string, amount: number): CreditBalance {
  let totalCredits = creditBalance.totalCredits ?? 0;
  let totalAccountCredits = creditBalance.totalAccountCredits ?? 0;
  if (category === 'credits') {
    totalCredits += amount;
  } else if (category === 'account') {
    totalAccountCredits += amount;
  }
  return {
    totalCredits,
    totalAccountCredits,
  };
}
export default function creditManagementReducer(
  state = initState,
  action: any
): CreditManagementState {
  switch (action.type) {
    case CreditManagementActionEvents.GET_ORGANISATION_BY_ID: {
      return { ...state, agent: null, organisation: action.payload };
    }

    case CreditManagementActionEvents.GET_AGENT_BY_ID: {
      return { ...state, organisation: null, agent: action.payload };
    }

    case CreditManagementActionEvents.ADD_CREDIT: {
      return {
        ...state,
        error: null,
        credits: [action.payload, ...state.credits],
        creditBalance: updateCreditBalance(state.creditBalance, action.payload.category, action.payload.amount),
      };
    }

    case CreditManagementActionEvents.GET_CREDITS_BY_ORGANISATION:
    case CreditManagementActionEvents.GET_CREDITS_BY_AGENT: {
      return {
        ...state,
        error: null,
        credits: action.payload,
      };
    }

    case CreditManagementActionEvents.UPDATE_CREDIT: {
      const creditsCopy = state.credits.slice();
      const idx = creditsCopy.findIndex((credit: Credit) => credit._id === action.payload._id);
      creditsCopy.splice(idx, 1, action.payload);
      return {
        ...state,
        error: null,
        credits: [...creditsCopy],
        creditBalance: updateCreditBalance(state.creditBalance, action.payload.category, action.payload.amount),
      };
    }

    case CreditManagementActionEvents.DELETE_CREDIT: {
      const creditsCopy = state.credits.slice();
      const idx = creditsCopy.findIndex((credit: Credit) => credit._id === action.payload._id);
      creditsCopy.splice(idx, 1);
      return {
        ...state,
        error: null,
        credits: [...creditsCopy],
        creditBalance: updateCreditBalance(state.creditBalance, action.payload.category, -action.payload.amount),
      };
    }
    // Add get total credits by organisation and agent cases
    case CreditManagementActionEvents.GET_TOTAL_CREDITS_BY_ORGANISATION:
    case CreditManagementActionEvents.GET_TOTAL_CREDITS_BY_AGENT: {
      return {
        ...state,
        error: null,
        creditBalance: {
          totalCredits: action.payload.totalCredits,
          totalAccountCredits: action.payload.totalAccountCredits
        },
      };
    }

    case CreditManagementActionEvents.ERROR_GETTING_ORGANISATION_BY_ID:
    case CreditManagementActionEvents.ERROR_GETTING_AGENT_BY_ID:
    case CreditManagementActionEvents.ERROR_ADDING_CREDIT:
    case CreditManagementActionEvents.ERROR_GETTING_CREDITS_BY_ORGANISATION:
    case CreditManagementActionEvents.ERROR_GETTING_CREDITS_BY_AGENT:
    case CreditManagementActionEvents.ERROR_UPDATING_CREDIT:
    case CreditManagementActionEvents.ERROR_DELETING_CREDIT:
    case CreditManagementActionEvents.ERROR_GETTING_TOTAL_CREDITS_BY_ORGANISATION:
    case CreditManagementActionEvents.ERROR_GETTING_TOTAL_CREDITS_BY_AGENT: {
      return {
        ...state,
        error: action.error,
      };
    }

    case CreditManagementActionEvents.CLEAR_CREDIT_MANAGEMENT_STATE:
      return {
        ...initState
      };

    default: {
      break;
    }
  }
  return state;
}
