import axios from 'axios';
import _ from 'lodash';

import { CallApiObject } from '../state/user-look-up/reducers/types';

const API_URL: any = process.env.REACT_APP_API_URL;

export const callApi = (payload: CallApiObject): Promise<any> => {
  const state = _.has(payload, "getState") ? payload.getState() : undefined;
  const token = _.has(state, "auth.token") ? state.auth.token : undefined;

  return axios({
    method: payload.method,
    url: `${API_URL}/${payload.endpoint}`,
    data: payload.data,
    headers: {
      "Accept-Version": "v1",
      Authorization: `Bearer ${token}`
    }
  })
    .then((res: any) => {
      return Promise.resolve(res);
    })
    .catch((error: any) => {
      return Promise.reject(error);
    });
};
