import { TargetingTemplateActionEvents } from "../actions/targeting-template.action.enums";
import { TargetingTemplatesState } from "./types";

const initState: TargetingTemplatesState = {
  organisations: [],
  targetingTemplates: [],
  loader: false,
  error: null,
};

export default function targetingTemplateReducer(
  state = initState,
  action: any
): TargetingTemplatesState {
  switch (action.type) {
    case TargetingTemplateActionEvents.GET_ALL_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload,
      };
    }

    case TargetingTemplateActionEvents.GET_ALL_TARGETING_TEMPLATES: {
      return {
        ...state,
        targetingTemplates: action.payload,
      };
    }

    case TargetingTemplateActionEvents.ADD_TARGETING_TEMPLATE: {
      return {
        ...state,
        targetingTemplates: action.payload,
      };
    }

    case TargetingTemplateActionEvents.LOADER: {
      return { ...state, loader: action.bool };
    }

    default: {
      break;
    }
  }
  return state;
}
