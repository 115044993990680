import CryptoJS from 'crypto-js';

const encInfo = function(info: string, secret: string): string {
  return CryptoJS.RC4.encrypt(info.trim(), secret)
    .toString()
    .trim();
};

const decInfo = function(encryptedInfo: any, secret: string): any {
  try {
    if (typeof encryptedInfo === "string") {
      return CryptoJS.RC4.decrypt(encryptedInfo.trim(), secret).toString(
        CryptoJS.enc.Utf8
      );
    } else {
      return CryptoJS.RC4.decrypt(
        encryptedInfo.toString().trim(),
        secret
      ).toString(CryptoJS.enc.Utf8);
    }
  } catch (error) {
    localStorage.removeItem("token");
  }
};

export { decInfo, encInfo };
