import { Reducer } from "redux";

import { flowPlanEnums as EVENT } from "../actions/flow-plan.actions.enums";
import { FlowPlanInitState } from "./types";

const initState: FlowPlanInitState = {
  flowPlans: [],
  flowPlan: undefined,
  organisations: [],
  loader: false,
  error: null,
};

const flowPlanReducer: Reducer<FlowPlanInitState> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case EVENT.GET_ALL_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload,
      };
    }
    case EVENT.GET_ALL_PLANS: {
      return { ...state, flowPlans: action.payload };
    }

    case EVENT.CREATE_FLOW_PLAN: {
      return { ...state, flowPlan: action.payload };
    }

    case EVENT.FLOW_PLAN_ERROR: {
      return { ...state, error: action.error };
    }

    default: {
      break;
    }
  }
  return state;
};

export default flowPlanReducer;
