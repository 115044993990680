import { AdTemplateActionEvents } from '../actions/ad-template.action.event.enums';
import { AdTemplate, AdTemplatesState } from './types';

const initState: AdTemplatesState = {
  organisations: [],
  leadFormTemplates: [],
  adTemplates: [],
  loader: false,
  error: null
};

export default function adTemplateReducer(
  state = initState,
  action: any
): AdTemplatesState {
  switch (action.type) {
    case AdTemplateActionEvents.GET_ALL_ORGANISATIONS: {
      return {
        ...state,
        error: null,
        organisations: action.payload,
      };
    }

    case AdTemplateActionEvents.GET_ALL_LEAD_FORMS: {
      return {
        ...state,
        error: null,
        leadFormTemplates: action.payload,
      };
    }

    case AdTemplateActionEvents.GET_ALL_AD_TEMPLATES: {
      return {
        ...state,
        error: null,
        adTemplates: action.payload,
      };
    }

    case AdTemplateActionEvents.ADD_AD_TEMPLATE: {
      return {
        ...state,
        error: null,
        adTemplates: [action.payload, ...state.adTemplates],
      };
    }

    case AdTemplateActionEvents.UPDATE_AD_TEMPLATE: {
      const adTemplatesCopy = state.adTemplates.slice();
      const idx = adTemplatesCopy.findIndex((adTemplate: AdTemplate) => adTemplate._id === action.payload._id);
      adTemplatesCopy.splice(idx, 1, action.payload);
      return {
        ...state,
        error: null,
        adTemplates: [...adTemplatesCopy],
      };
    }

    case AdTemplateActionEvents.DELETE_AD_TEMPLATE: {
      const adTemplatesCopy = state.adTemplates.slice();
      const idx = adTemplatesCopy.findIndex((adTemplate: AdTemplate) => adTemplate._id === action.payload._id);
      adTemplatesCopy.splice(idx, 1);
      return {
        ...state,
        error: null,
        adTemplates: [...adTemplatesCopy],
      };
    }
    
    case AdTemplateActionEvents.ERROR_GETTING_ALL_ORGANISATIONS:
    case AdTemplateActionEvents.ERROR_GETTING_ALL_AD_TEMPLATES:
    case AdTemplateActionEvents.ERROR_ADDING_AD_TEMPLATE:
    case AdTemplateActionEvents.ERROR_UPDATING_AD_TEMPLATE:
    case AdTemplateActionEvents.ERROR_DELETING_AD_TEMPLATE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case AdTemplateActionEvents.SET_AD_TEMPLATES_LOADER: {
      return { ...state, loader: action.bool };
    }

    default: {
      break;
    }
  }
  return state;
}
