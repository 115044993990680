export enum userLookUpActionEvents {
  GET_USER_BY_EMAIL = "GET_USER_BY_EMAIL",
  GET_USER_BY_EMAIL_ERROR = "GET_USER_BY_EMAIL_ERROR",

  GET_CLAIMED_BY_ID = "GET_CLAIMED_BY_ID",

  OPEN_POINTS_MODAL = "OPEN_POINTS_MODAL",

  CHECK_SELECTED_TAG = "CHECK_SELECTED_TAG",

  GET_TAGS_REQUEST = "GET_TAGS_REQUEST",
  GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS",
  GET_TAGS_FAILURE = "GET_TAGS_FAILURE",

  // Lease
  GET_LEASE_REQUEST = "GET_LEASE_REQUEST",
  GET_LEASE_SUCCESS = "GET_LEASE_SUCCUESS",
  GET_LEASE_FAILURE = "GET_LEASE_FAILURE",

  // Verify user
  UPDATE_ACCOUNT_AS_VERIFIED = "UPDATE_ACCOUNT_AS_VERIFIED",
  UPDATE_ACCOUNT_AS_VERIFIED_FAILURE = "UPDATE_ACCOUNT_AS_VERIFIED_FAILURE",

  // UPDATE ACCOUNT STATUS
  UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST",
  UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS",
  UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE",

  // Update account type
  UPDATE_ACCOUNT_TYPE_REQUEST = "UPDATE_ACCOUNT_TYPE_REQUEST",
  UPDATE_ACCOUNT_TYPE_SUCCESS = "UPDATE_ACCOUNT_TYPE_SUCCESS",
  UPDATE_ACCOUNT_TYPE_FAILURE = "UPDATE_ACCOUNT_TYPE_FAILURE",

  // update user login type
  RESET_USER_PASSWORD_REQUEST = "RESET_USER_PASSWORD_REQUEST",
  RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS",
  RESET_USER_PASSWORD_FAILURE = "RESET_USER_PASSWORD_FAILURE",

  CHANGE_LOGIN_TYPE_REQUEST = "CHANGE_LOGIN_TYPE_REQUEST",
  CHANGE_LOGIN_TYPE_SUCCESS = "CHANGE_LOGIN_TYPE_SUCCESS",
  CHANGE_LOGIN_TYPE_FAILURE = "CHANGE_LOGIN_TYPE_FAILURE",

  UPDATE_USER_AS_ADMIN_REQUEST = "UPDATE_USER_AS_ADMIN_REQUEST",
  UPDATE_USER_AS_ADMIN_SUCCESS = "UPDATE_USER_AS_ADMIN_SUCCESS",
  UPDATE_USER_AS_ADMIN_FAILURE = "UPDATE_USER_AS_ADMIN_FAILURE",

  DELETE_USER_REQUEST = "DELETE_USER_REQUEST",
  DELETE_USER_REQUEST_SUCCESS = "DELETE_USER_REQUEST_SUCCESS",
  DELETE_USER_REQUEST_FAILURE = "DELETE_USER_REQUEST_FAILURE",

  GET_USER_TRANSACTIONS_BY_EMAIL = "GET_USER_TRANSACTIONS_BY_EMAIL",
  GET_USER_TRANSACTIONS_BY_EMAIL_ERROR = "GET_USER_TRANSACTIONS_BY_EMAIL_ERROR",
}

export const userStatus = {
  BLOCKED: "Blocked",
  PENDING_VERIFICATION: "Pending Verification",
  VERIFIED: "Verified",
  UNLOCK_ACCOUNT: "UnLock Account",
};

export const accountTypes = {
  STANDARD: "Standard",
  //PRO: 'Pro',
  ENTERPRISE: "Enterprise",
};
