export enum campaignActionEvents {
  GET_CAMPAIGNS = "GET_CAMPAIGNS",
  SET_CAMPAIGN_LOADER = "SET_CAMPAIGN_LOADER",
  ERROR_GETTING_CAMPAIGNS = "ERROR_GETTING_CAMPAIGNS",
  ADD_CAMPAIGNS = "ADD_CAMPAIGNS",
  ERROR_ADDING_CAMPAIGNS = "ERROR_ADDING_CAMPAIGNS",
  ERROR_EDDITING_CAMPAIGNS = "ERROR_EDDITING_CAMPAIGNS",

  //modals
  TOGGLE_EDIT_CAMPAIGN_MODAL = "TOGGLE_EDIT_CAMPAIGN_MODAL",
  TOGGLE_CAMPAIGN_ADD_MODAL = "TOGGLE_CAMPAIGN_ADD_MODAL",
  TOGGLE_CAMPAIGN_VIEW_MODAL = "TOGGLE_CAMPAIGN_VIEW_MODAL",
  CLICKED_CAMPAIGN_ROW_DATA = "CLICKED_CAMPAIGN_ROW_DATA",
  CLOSE_CAMPAIGN_MODAL = "CLOSE_CAMPAIGN_MODAL",
  TOGGLE_MENU = "TOGGLE_MENU",
  ADDED_FIELDS = "ADDED_FIELDS",
  SET_FIELDS = "SET_FIELDS"
}
