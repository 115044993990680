import { LeadFormActionEvents } from "../actions/lead-form.action.enums";
import { LeadFormInitState } from "./types";

const initState: LeadFormInitState = {
  organisations: [],
  leadForms: [],
  loader: false,
  error: null,
};

export default function leadFormReducer(
  state = initState,
  action: any
): LeadFormInitState {
  switch (action.type) {
    case LeadFormActionEvents.GET_ALL_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload,
      };
    }

    case LeadFormActionEvents.GET_ALL_LEAD_FORMS: {
      return {
        ...state,
        leadForms: action.payload,
      };
    }

    case LeadFormActionEvents.ADD_LEAD_FORM: {
      return {
        ...state,
        leadForm: action.payload,
      };
    }

    case LeadFormActionEvents.LOADER: {
      return { ...state, loader: action.bool };
    }

    default: {
      break;
    }
  }
  return state;
}
