import { Reducer } from 'redux';

import { campaignActionEvents as EVENT } from '../../campaigns/actions/campaign.action.event.enums';
import { campaignInitState } from './types';

const initState: campaignInitState = {
  campaigns: [],
  partners: [],
  loader: false,
  error: null
};

const campaignReducer: Reducer<campaignInitState> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case EVENT.GET_CAMPAIGNS: {
      return { ...state, campaigns: action.payload };
    }

    case EVENT.ADD_CAMPAIGNS: {
      return { ...state, campaigns: [...action.payload, ...state.campaigns] };
    }

    case EVENT.ERROR_GETTING_CAMPAIGNS: {
      return { ...state, error: action.error };
    }

    case "GET_PARTNERS": {
      return { ...state, partners: action.payload };
    }

    case EVENT.ERROR_EDDITING_CAMPAIGNS: {
      return { ...state, error: action.error };
    }

    case "ERROR_GETTING_PARTNERS": {
      return { ...state, partners: action.payload };
    }
    case EVENT.SET_CAMPAIGN_LOADER: {
      return { ...state, loader: action.bool };
    }

    default: {
      break;
    }
  }
  return state;
};

export default campaignReducer;
