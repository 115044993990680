import { get, has } from 'lodash';
import { REHYDRATE } from 'redux-persist';

import { authActionEvents as EVENT } from '../actions/auth.actions.event.enums';

export default function reducer(
  state = {
    response: {},
    error: null,
    token: null,
    isAuthenticated: false
  },
  action: any
) {
  switch (action.type) {
    case EVENT.USER_AUTH: {
      return { ...state, response: action.message, token: action.token, isAuthenticated: !!action.token };
    }

    case EVENT.USER_AUTH_ERROR: {
      return { ...state, response: action.message, token: null, isAuthenticated: false };
    }

    case EVENT.LOG_OUT: {
      return { ...state, response: null, token: null, isAuthenticated: false };
    }

    case REHYDRATE: {
      return {
        ...state,
        token: has(action, "payload.auth.token")
          ? action.payload.auth.token
          : null,
        isAuthenticated: !!get(action, "payload.auth.token")
      };
    }

    default: {
      break;
    }
  }
  return state;
}
