import axios from "axios";
// import { get } from "lodash";
import NProgress from "nprogress";

import { onError, onSuccess } from "../../../util/actionResponseHandler";
import { callApi } from "../../../util/callApi";
import { decInfo } from "../../../util/crypto.lib";
import alerts from "../../../views/Components/Toast/index";
// import { resetUserPassword } from '../../auth/actions/auth.action';
import { authActionEvents as AUTH } from "../../auth/actions/auth.actions.event.enums";
import { Tags, User } from "../reducers/types";
import { userLookUpActionEvents as EVENT } from "./user.lookup.action.event.enums";

const API_URL: any = process.env.REACT_APP_API_URL;

//Get campaigns
const rawJWT: any = localStorage.getItem("token"); //cast to string
const jwt = decInfo(rawJWT, "token");
const headers: object = {
  "Accept-Version": "v1",
  Authorization: "Bearer " + jwt,
  "Content-Type": "application/json",
};
export const searchUser =
  (email: string) => (dispatch: Function, getState: Function) => {
    NProgress.set(0.4);
    callApi({
      method: "GET",
      endpoint: `admin/users/?email=${email}`,
      getState,
      data: {},
    })
      .then((res: any) => {
        res.data[0]
          ? alerts("success", "Successful", "BOTTOM_RIGHT")
          : alerts("warning", "No record found !!", "BOTTOM_RIGHT");

        dispatch({ type: EVENT.GET_USER_BY_EMAIL, payload: res.data[0] || [] });

        /**
         * Decommission value added services transactions
     const userId = get(res, 'data[0]._id', undefined);

      if (userId) {
        callApi({
          method: "GET",
          endpoint: `admin/users/${userId}/transactions`,
          getState,
          data: {},
        })
          .then((res: any) => {
            res.data[0]
              ? alerts("success", "Successful", "BOTTOM_RIGHT")
              : alerts("warning", "No transactions were found", "BOTTOM_RIGHT");

            dispatch({
              type: EVENT.GET_USER_TRANSACTIONS_BY_EMAIL,
              payload: res.data || [],
            });
          })
          .catch((error: any) => {
            const mess = error.message;
            if (mess.includes(403)) {
              dispatch({
                type: AUTH.USER_AUTH_ERROR,
                message: {
                  code: 401,
                  message: "Not authorized",
                },
              });
            } else {
              alerts("error", error.message, "BOTTOM_RIGHT");
              dispatch({
                type: EVENT.GET_USER_TRANSACTIONS_BY_EMAIL_ERROR,
                error: error,
              });
            }
          });
      }
      */
      })
      .catch((error: any) => {
        const mess = error.message;
        if (mess.includes(403)) {
          dispatch({
            type: AUTH.USER_AUTH_ERROR,
            message: {
              code: 401,
              message: "Not authorized",
            },
          });
        } else {
          alerts("error", error.message, "BOTTOM_RIGHT");
          dispatch({
            type: EVENT.GET_USER_BY_EMAIL_ERROR,
            error: error,
          });
        }
      });
    NProgress.done();
  };

//get enterprise account
export const getEnterpriseAccounts =
  () => async (dispatch: Function, getState: Function) => {
    try {
      const res = await callApi({
        method: "GET",
        endpoint: `accounts/enterprise?state=Active`,
        getState,
        data: {},
      });

      const accounts = res.data
        .filter((account: any) => account.user !== null)
        .map((account: any) => ({
          email: account.user.email,
          id: account.user.id,
        }));
      return { success: true, data: accounts };
    } catch (error) {
      return { success: false, data: error };
    }
  };

//Get lease
export function getLease(userId: string) {
  return function (dispatch: Function, getState: Function) {
    dispatch({ type: EVENT.GET_LEASE_REQUEST });
    callApi({
      method: "GET",
      endpoint: `leaseAgreement/${userId}/leaseAgreements`,
      data: {},
      getState,
    })
      .then((res: any) => {
        dispatch(
          onSuccess({
            type: EVENT.GET_LEASE_SUCCESS,
            payload: res.data,
          })
        );
      })
      .catch((error: any) => {
        dispatch(onError({ type: EVENT.GET_LEASE_FAILURE, error }));
      });
  };
}

//Allocate points
export function flowScoreEvent(email: string, payload: any): Function {
  return function (dispatch: Function, getState: Function) {
    callApi({
      method: "POST",
      endpoint: "flowscore-events",
      getState,
      data: payload,
    })
      .then((res: any) => {
        alerts("success", "Points allocated!", "BOTTOM_RIGHT");
        dispatch({ type: EVENT.OPEN_POINTS_MODAL, status: false });
        dispatch(searchUser(email));
      })
      .catch((error) => {
        alerts("error", error.message);
      });
  };
}
//get flowscore result
export async function getFlowCampaing(id: string) {
  try {
    const claimed = await axios({
      method: "GET",
      url: `${API_URL}/marketplace-deals?userId=${id}`,
      headers: headers,
    });

    return claimed.data;
  } catch (error) {
    console.log(error);
  }
}

//set user status
export const changeAccountStatus =
  (id: string, payload: any) => (dispatch: Function, getState: any) => {
    dispatch({ type: EVENT.UPDATE_USER_STATUS_REQUEST });
    callApi({
      method: "PUT",
      endpoint: `admin/users/${id}`,
      data: payload,
      getState,
    })
      .then((res: any) => {
        dispatch(
          onSuccess({
            type: EVENT.UPDATE_USER_STATUS_SUCCESS,
            payload: res.data,
            message: "User has been changed",
          })
        );
      })
      .catch((error: any) => {
        dispatch(onError({ type: EVENT.UPDATE_USER_STATUS_FAILURE, error }));
      });
  };

//set account type
export const changeAccountType =
  (id: string, payload: any) => (dispatch: Function, getState: any) => {
    dispatch({ type: EVENT.UPDATE_ACCOUNT_TYPE_REQUEST });
    callApi({
      method: "PUT",
      endpoint: `admin/accounts/${id}`,
      data: payload,
      getState,
    })
      .then((res: any) => {
        dispatch(
          onSuccess({
            type: EVENT.UPDATE_ACCOUNT_TYPE_SUCCESS,
            payload: res.data,
            message: "Account Status been changed",
          })
        );
      })
      .catch((error: any) => {
        dispatch(onError({ type: EVENT.UPDATE_ACCOUNT_TYPE_FAILURE, error }));
      });
  };

export const checkTag = (id: string, makeTrue: boolean = false): Function => {
  return (dispatch: Function) => {
    dispatch({ type: EVENT.CHECK_SELECTED_TAG, id, makeTrue });
  };
};

export const getTags = () => (dispatch: Function, getState: Function) => {
  dispatch({ type: EVENT.GET_TAGS_REQUEST });
  callApi({ method: "GET", endpoint: "tags", data: {}, getState })
    .then((res: any) => {
      dispatch(
        onSuccess({
          type: EVENT.GET_TAGS_SUCCESS,
          payload: res.data,
        })
      );
    })
    .catch((error: any) => {
      dispatch(onError({ type: EVENT.GET_TAGS_FAILURE, error }));
    });
};

export const resetUserPassword = (payload: User) => {
  return function (dispatch: Function) {
    dispatch({ type: EVENT.RESET_USER_PASSWORD_REQUEST });

    axios({
      method: "POST",
      url: `${API_URL}/auth/forgot`,
      data: { email: payload.email },
      headers: {
        "Accept-Version": "v1",
        "Content-Type": "application/json",
      },
    })
      .then((response: any) => {
        alerts("success", response.data.message, "BOTTOM_RIGHT");
        dispatch({
          type: EVENT.RESET_USER_PASSWORD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        alerts(
          "error",
          "Could not send reset password mailer. Request password reset manaully",
          "BOTTOM_RIGHT"
        );
        dispatch({ type: EVENT.RESET_USER_PASSWORD_FAILURE, payload: error });
      });
  };
};

export const changeUserLoginType =
  (payload: User) => (dispatch: Function, getState: Function) => {
    dispatch({ type: EVENT.CHANGE_LOGIN_TYPE_REQUEST });
    callApi({
      method: "PUT",
      endpoint: `admin/users/${payload._id}/provider`,
      data: { providerType: "local" },
      getState,
    })
      .then((res: any) => {
        dispatch(resetUserPassword(payload));
        dispatch(
          onSuccess({
            type: EVENT.CHANGE_LOGIN_TYPE_SUCCESS,
            payload: res.data,
            message: "Login type changed successfuly!",
          })
        );
      })
      .catch((error: any) => {
        dispatch(onError({ type: EVENT.CHANGE_LOGIN_TYPE_FAILURE, error }));
      });
  };

//Verify user account '/api/accounts/:accountId'
export const verifyAccount =
  (id: string, payload: any) => (dispatch: Function, getState: any) => {
    dispatch({ type: EVENT.CHANGE_LOGIN_TYPE_REQUEST });
    callApi({
      method: "PUT",
      endpoint: `admin/accounts/${id}`,
      data: {
        accountVerified: true,
        verifiedSource: "Admin",
        verifiedDate: Date.now(),
      },
      getState,
    })
      .then((res: any) => {
        dispatch(
          onSuccess({
            type: EVENT.UPDATE_ACCOUNT_AS_VERIFIED,
            payload: Object.assign(payload, { account: res.data }),
            message: "User has been verified!",
          })
        );
      })
      .catch((error: any) => {
        dispatch(
          onError({ type: EVENT.UPDATE_ACCOUNT_AS_VERIFIED_FAILURE, error })
        );
      });
  };

export const updateUserAsAdmin =
  (payload: User) => (dispatch: Function, getState: any) => {
    // return function(dispatch: Function) {
    dispatch({ type: EVENT.UPDATE_USER_AS_ADMIN_REQUEST });
    callApi({
      method: "PUT",
      endpoint: `admin/users/${payload._id}`,
      data: payload,
      getState,
    })
      .then((res: any) => {
        dispatch(
          onSuccess({
            type: EVENT.UPDATE_USER_AS_ADMIN_SUCCESS,
            payload: res.data,
          })
        );
      })
      .catch((error: any) => {
        dispatch(onError({ type: EVENT.UPDATE_USER_AS_ADMIN_FAILURE, error }));
      });
    // };
  };

export const convertMongooseArrayToArray = (tags: any): string[] => {
  let derivedArray: string[] = [];

  Object.keys(tags).forEach((objKey) => {
    let tag: string = "";
    Object.keys(tags[objKey]).forEach((itemKey) => {
      tag += tags[objKey][itemKey];
    });
    derivedArray.push(tag);
  });

  return derivedArray;
};

export const initializeTags = (tags: Tags[]): Tags[] => {
  tags.forEach((tag) => {
    tag.isChecked = false;
  });
  return tags;
};

export const checkTagById = (
  tags: Tags[],
  id: string,
  makeTrue: boolean = false
): Tags[] => {
  tags.forEach((tag) => {
    if (tag._id === id) tag.isChecked = makeTrue ? makeTrue : !tag.isChecked;
  });

  return tags;
};

export const userTransactions =
  (userId: string) => (dispatch: Function, getState: Function) => {
    NProgress.done();
  };
