export enum flowPlanEnums {
  GET_ALL_ORGANISATIONS = "GET_ALL_ORGANISATIONS",
  GET_ALL_PLANS = "GET_ALL_PLANS",
  CREATE_FLOW_PLAN = "CREATE_FLOW_PLAN",
  UPDATE_FLOW_PLAN = "UPDATE_FLOW_PLAN",
  GET_FLOW_PLAN_BY_ID = "GET_FLOW_PLAN_BY_ID",
  DELETE_FLOW_PLAN = "DELETE_FLOW_PLAN",
  FLOW_PLAN_ERROR = "FLOW_PLAN_ERROR",
  LOADER = "LOADER"
}


