import { applyMiddleware, createStore } from "redux";
// import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./index";
import promise from "redux-promise-middleware";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(initialState) {
  const persistConfig = {
    key: "admin_cms",
    storage,
    whitelist: ["auth"],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  return createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(promise, thunk))
  );
}
