export enum partnersActionEvents {
  ERROR_GETTING_ALL_PARTNERS = "ERROR_GETTING_ALL_PARTNERS",
  GET_ALL_PARTNERS = "GET_ALL_PARTNERS",
  SET_PARTNER_LOADER = "SET_PARTNER_LOADER",
  ERROR_ADDING_PARTNERS = "ERROR_ADDING_PARTNERS",
  ERROR_EDDITING_PARTNERS = "ERROR_EDDITING_PARTNERS",
  GET_PARTNERS = "GET_PARTNERS",
  ADD_PARTNERS = "ADD_PARTNERS",
  ERROR_GETTING_PARTNERS = "ERROR_GETTING_PARTNERS",
  //modals
  TOGGLE_PARTNERS_ADD_MODAL = "TOGGLE_PARTNERS_ADD_MODAL",
  TOGGLE_PARTNERS_EDIT_MODAL = "TOGGLE_PARTNERS_EDIT_MODAL",
  TOGGLE_PARTNERS_VIEW_MODAL = "TOGGLE_PARTNERS_VIEW_MODAL",
  CLICKED_PARTNERS_ROW_DATA = "CLICKED_PARTNERS_ROW_DATA",
  CLOSE_PARTNERS_MODAL = "CLOSE_PARTNERS_MODAL"
}
