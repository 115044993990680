import { callApi } from '../../../util/callApi';
import { DefaultActionEvents } from './default.action.event.enums';

export const getEnums = () => (
  dispatch: Function,
  getState: Function
) => {
  callApi({
    method: 'GET',
    endpoint: 'enums',
    getState,
    data: {},
  })
    .then((res: any) => {
      if (!res.data) return;
      dispatch({ type: DefaultActionEvents.GET_ENUMS, payload: res.data });
    })
    .catch((error: any) => {
      dispatch({
        type: DefaultActionEvents.ERRORS,
        error: error,
      });
    });
};

export const getLocales = () => (
  dispatch: Function,
  getState: Function
) => {
  callApi({
    method: 'GET',
    endpoint: 'locales',
    getState,
    data: {},
  })
    .then((res: any) => {
      if (!res.data) return;
      dispatch({ type: DefaultActionEvents.GET_LOCALES, payload: res.data });
    })
    .catch((error: any) => {
      dispatch({
        type: DefaultActionEvents.ERRORS,
        error: error,
      });
    });
};
