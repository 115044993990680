import { combineReducers } from "redux";
import campaignReducer from "./campaigns/reducers";
import partnerReducer from "./partners/reducers";
import dashBoardReducer from "./dashboard/reducers";
import userLookUpReducer from "./user-look-up/reducers";
import acquiredVasReducer from "./vas/reducers";
import auth from "./auth/reducers/auth.reducer";
import behavioursReducer from "./behaviours/reducers/behaviours.reducer";
import creditManagementReducer from "./credit-management/reducers";
import defaultReducer from "./defaults/reducers";
import adTemplateReducer from "./ad-templates/reducers";
import targetingTemplateReducer from "./targeting-templates/reducers";
import flowPlanReducer from "./flow-plan/reducers";
import flowProductReducer from "./flow-products/reducers";
import leadFormReducer from "./lead-forms/reducers";

export default combineReducers({
  campaignReducer,
  userLookUpReducer,
  partnerReducer,
  dashBoardReducer,
  acquiredVasReducer,
  auth,
  behavioursReducer,
  creditManagementReducer,
  defaultReducer,
  adTemplateReducer,
  targetingTemplateReducer,
  flowPlanReducer,
  flowProductReducer,
  leadFormReducer
});
