export enum CreditManagementActionEvents {
    GET_ORGANISATION_BY_ID = 'GET_ORGANISATION_BY_ID',
    SET_ORGANISATION_LOADER = 'SET_ORGANISATION_LOADER',
    ERROR_GETTING_ORGANISATION_BY_ID = 'ERROR_GETTING_ORGANISATION_BY_ID',

    GET_AGENT_BY_ID = 'GET_AGENT_BY_ID',
    SET_AGENT_LOADER = 'SET_AGENT_LOADER',
    ERROR_GETTING_AGENT_BY_ID = 'ERROR_GETTING_AGENT_BY_ID',

    GET_CREDITS_BY_ORGANISATION = 'GET_CREDITS_BY_ORGANISATION',
    ERROR_GETTING_CREDITS_BY_ORGANISATION = 'ERROR_GETTING_CREDITS_BY_ORGANISATION',

    GET_CREDITS_BY_AGENT = 'GET_CREDITS_BY_AGENT',
    ERROR_GETTING_CREDITS_BY_AGENT = 'ERROR_GETTING_CREDITS_BY_AGENT',

    ADD_CREDIT = 'ADD_CREDIT',
    ERROR_ADDING_CREDIT = 'ERROR_ADDING_CREDIT',

    UPDATE_CREDIT = 'UPDATE_CREDIT',
    ERROR_UPDATING_CREDIT = 'ERROR_UPDATING_CREDIT',

    DELETE_CREDIT = 'DELETE_CREDIT',
    ERROR_DELETING_CREDIT = 'ERROR_DELETING_CREDIT',

    CLEAR_CREDIT_MANAGEMENT_STATE = 'CLEAR_CREDIT_MANAGEMENT_STATE',

    GET_TOTAL_CREDITS_BY_ORGANISATION = 'GET_TOTAL_CREDITS_BY_ORGANISATION',
    ERROR_GETTING_TOTAL_CREDITS_BY_ORGANISATION = 'ERROR_GETTING_TOTAL_CREDITS_BY_ORGANISATION',

    GET_TOTAL_CREDITS_BY_AGENT = 'GET_TOTAL_CREDITS_BY_AGENT',
    ERROR_GETTING_TOTAL_CREDITS_BY_AGENT = 'ERROR_GETTING_TOTAL_CREDITS_BY_AGENT',
}
