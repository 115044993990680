import { DefaultActionEvents } from '../actions/default.action.event.enums';
import { DefaultsState } from './types';

const initState: DefaultsState = {
  enums: {},
  locales: [],
  loader: false,
  error: null
};

export default function defaultReducer(
  state = initState,
  action: any
): DefaultsState {
  switch (action.type) {
    case DefaultActionEvents.GET_ENUMS: {
      return { ...state, enums: action.payload };
    }

    case DefaultActionEvents.GET_LOCALES: {
      return { ...state, locales: action.payload };
    }

    case DefaultActionEvents.ERRORS: {
      return { ...state, error: action.error };
    }

    default: {
      break;
    }
  }
  return state;
}
